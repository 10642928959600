ion-tab-bar {
    --background: #3c4d7a !important;
    --color: white;
    --color-selected: rgb(241, 241, 72);
    border: none;

}
ion-title {
    font: Baby sweet !important;
}

ion-toolbar {
    --ion-toolbar-background: #3c4d7a!important;
    --color: white;
}

p.warning{
    color: red;
}

ion-item-group:not(:has(ion-item)){
    display: none;
}